import { computed, inject, provide, reactive, ref } from 'vue'

export const initStore = () => {

    // localStorage - show in every tab, only can use one by one variable instead of a lumpsum object
    // sessionStorage, only show in current tab
    // State
    const auth = reactive({
        userId: localStorage.getItem('userId'),
        role: localStorage.getItem('role'),
        displayName: localStorage.getItem('displayName'),
        avatar: localStorage.getItem('avatar'),
        sessionId: localStorage.getItem('sessionId'),
        isVerified: localStorage.getItem('userIsVerified'), 
        status: localStorage.getItem('userStatus'),
        expired: localStorage.getItem('userExpired'),
        lastLogin: localStorage.getItem('userLastLogin'),
        wlId: localStorage.getItem('wlId'),
        entityId: localStorage.getItem('entityId'),
        package: localStorage.getItem('package'),
        structure: localStorage.getItem('structure'),
        openId: localStorage.getItem('openId')
        
    })

    const proj = reactive({
        id: localStorage.getItem('projectId'),
        name: localStorage.getItem('projectName')
    })

    /* const doc = reactive({
        json: ''
    }) */

    const nav = reactive({
        page: localStorage.getItem('page'),
        totalPage: localStorage.getItem('totalPage')
    })

    const theme = ref(localStorage.getItem('theme'))

    // Getter
    const getUserId = computed(() => auth.userId)
    const getWlId = computed(() => auth.wlId)
    const getEntityId = computed(() => auth.entityId)
    const getPackage = computed(() => auth.package)
    const getStructure = computed(() => auth.structure)
    const getOpenId = computed(() => auth.openId)
    const getRole = computed(() => auth.role)
    const getDisplayName = computed(() => auth.displayName)
    const getAvatar = computed(() => auth.avatar)
    const getSessionId = computed(() => auth.sessionId)
    const getUserIsVerified = computed(() => auth.isVerified)
    const getUserStatus = computed(() => auth.status)
    const getUserExpired = computed(() => auth.expired)
    const getUserLastLogin = computed(() => auth.lastLogin)
    const getProjectId = computed(() => proj.id)
    const getProjectName = computed(() => proj.name)
    const getPage = computed(() => nav.page)
    const getTotalPage = computed(() => nav.totalPage)

    const getTheme = computed(() => theme.value)

    // Action
    const updUserId = (newValue='') => {
        if (newValue === null || newValue === 'null' || newValue === '') {

            localStorage.removeItem('userId')
        } else {

            auth.userId = newValue
            localStorage.setItem('userId', newValue)
        }
    }

    const updWlId = (newValue='') => {
        if (newValue === null || newValue === 'null' || newValue === '') {

            localStorage.removeItem('wlId')
        } else {

            auth.wlId = newValue
            localStorage.setItem('wlId', newValue)
        }
    }

    const updEntityId = (newValue='') => {
        if (newValue === null || newValue === 'null' || newValue === '') {

            localStorage.removeItem('entityId')
        } else {

            auth.entityId = newValue
            localStorage.setItem('entityId', newValue)
        }
    }

    const updPackage = (newValue='') => {
        if (newValue === null || newValue === 'null' || newValue === '') {

            localStorage.removeItem('package')
        } else {

            auth.package = newValue
            localStorage.setItem('package', newValue)
        }
    }


    const updOpenId = (newValue='') => {
        if (newValue === null || newValue === 'null' || newValue === '') {

            localStorage.removeItem('openId')
        } else {

            auth.openId = newValue
            localStorage.setItem('openId', newValue)
        }
    }

    const updStructure = (newValue='') => {
        if (newValue === null || newValue === 'null' || newValue === '') {

            localStorage.removeItem('structure')
        } else {

            auth.structure = newValue
            localStorage.setItem('structure', newValue)
        }
    }

    const updSessionId = (newValue='') => {
        if (newValue === null || newValue === 'null' || newValue === '') {
            localStorage.removeItem('sessionId')
        } else {
            auth.sessionId = newValue
            localStorage.setItem('sessionId', newValue)
        }
    }

    const updDisplayName = (newValue='') => {
        auth.displayName = newValue
        localStorage.setItem('displayName', newValue)
    }

    const updAvatar = (newValue='') => {
        auth.avatar = newValue
        localStorage.setItem('avatar', newValue)
    }

    const updUserIsVerified = (newValue='') => {
        auth.isVerified = newValue
        localStorage.setItem('userIsVerified', newValue)
    }

    const updUserStatus = (newValue='') => {
        auth.status = newValue
        localStorage.setItem('userStatus', newValue)
    }

    const updUserExpired = (newValue='') => {
        auth.expired = newValue
        localStorage.setItem('userExpired', newValue)
    }

    const updUserLastLogin = (newValue='') => {
        auth.lastLogin = newValue
        localStorage.setItem('userLastLogin', newValue)
    }

    const updProjectId = (newValue='') => {
        proj.id = newValue
        localStorage.setItem('projectId', newValue)
    }

    const updProjectName = (newValue='') => {
        proj.name = newValue
        localStorage.setItem('projectName', newValue)
    }

    const updTotalPage = (newValue='') => {
        nav.totalPage = newValue
        localStorage.setItem('totalPage', newValue)
    }

    const updTheme = (newValue='') => {
        theme.value = newValue
        localStorage.setItem('theme', newValue)
    }

    provide('getUserId', getUserId)
    provide('getWlId', getWlId)
    provide('getEntityId', getEntityId)
    provide('getPackage', getPackage)
    provide('getStructure', getStructure)
    provide('getOpenId', getOpenId)
    provide('getRole', getRole)
    provide('getDisplayName', getDisplayName)
    provide('getAvatar', getAvatar)
    provide('getSessionId', getSessionId)
    provide('getUserIsVerified', getUserIsVerified)
    provide('getUserStatus', getUserStatus)
    provide('getUserExpired', getUserExpired)
    provide('getUserLastLogin', getUserLastLogin)
    provide('getProjectId', getProjectId)
    provide('getProjectName', getProjectName)
    provide('getPage', getPage)
    provide('getTotalPage', getTotalPage)
    provide('getTheme', getTheme)

    provide('updUserId', updUserId)
    provide('updWlId', updWlId)
    provide('updEntityId', updEntityId)
    provide('updPackage', updPackage)
    provide('updStructure', updStructure)
    provide('updOpenId', updOpenId)
    provide('updSessionId', updSessionId)
    provide('updDisplayName', updDisplayName)
    provide('updAvatar', updAvatar)
    provide('updUserIsVerified', updUserIsVerified)
    provide('updUserStatus', updUserStatus)
    provide('updUserExpired', updUserExpired)
    provide('updUserLastLogin', updUserLastLogin)
    provide('updProjectId', updProjectId)
    provide('updProjectName', updProjectName)
    provide('updTotalPage', updTotalPage)
    provide('updTheme', updTheme)

    return { 
        getUserId, getWlId, getEntityId, getPackage, getStructure, getOpenId, getRole, getDisplayName, getAvatar, getSessionId, getUserIsVerified, getUserStatus, getUserExpired, getUserLastLogin, 
        getProjectId, getProjectName, getPage, getTotalPage, getTheme,
        updUserId, updWlId, updEntityId, updPackage, updStructure, updOpenId, updSessionId, updDisplayName, updAvatar, updUserIsVerified, updUserStatus, updUserExpired, updUserLastLogin, 
        updProjectId, updProjectName, updTotalPage, updTheme
    }
}

export const useStore = () => ({
    getUserId: inject('getUserId'),
    getWlId: inject('getWlId'),
    getEntityId: inject('getEntityId'),
    getPackage: inject('getPackage'),
    getStructure: inject('getStructure'),
    getOpenId: inject('getOpenId'),
    getRole: inject('getRole'),
    getDisplayName: inject('getDisplayName'),
    getAvatar: inject('getAvatar'),
    getSessionId: inject('getSessionId'),
    getUserIsVerified: inject('getUserIsVerified'),
    getUserStatus: inject('getUserStatus'),
    getUserExpired: inject('getUserExpired'),
    getUserLastLogin: inject('getUserLastLogin'),
    getProjectId: inject('getProjectId'),
    getProjectName: inject('getProjectName'),
    getPage: inject('getPage'),
    getTotalPage: inject('getTotalPage'),
    getTheme: inject('getTheme'),
    updUserId: inject('updUserId'),
    updWlId: inject('updWlId'),
    updEntityId: inject('updEntityId'),
    updPackage: inject('updPackage'),
    updStructure: inject('updStructure'),
    updOpenId: inject('updOpenId'),
    updSessionId: inject('updSessionId'),
    updDisplayName: inject('updDisplayName'),
    updAvatar: inject('updAvatar'),
    updUserIsVerified: inject('updUserIsVerified'),
    updUserStatus: inject('updUserStatus'),
    updUserExpired: inject('updUserExpired'),
    updUserLastLogin: inject('updUserLastLogin'),
    updProjectId: inject('updProjectId'),
    updProjectName: inject('updProjectName'),
    updTotalPage: inject('updTotalPage'),
    updTheme: inject('updTheme')
})
